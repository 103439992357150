/**
 * 关于我们
 */
export const p1 = "是全面物流解决方案的提供者。为各个不同行业服务，在供应链的不同环节，通过向客户提供国际先进的物流管理技术、经验和高品质的物流相关系统，通过持续的物流改善，协助客户在降低其物流总成本条件下实现既定的客户服务水平，提高企业物流能力和市场核心竞争能力。"
export const p2 = "提供工厂物流系统、自动化仓储系统、输送分拣系统、仓储信息管理系统等物流装备的系统规划及集成服务。";
export const p3 = "公司拥有强大的研发团队、先进的生产研发基地和一流的项目管理及售后服务体系，拥有多项国家发明专利、实用新型专利，以及软件产品登记证证书等，并通过ISO9001:2015质量管理体系、ISO14001:2015环境管理体系、ISO45001:2018职业健康安全管理体系的认证。";

/**
 * 团队介绍
 */
export const i1 = "建航科技在智能物流系统工程领域拥有一支由专业水平高、事业心强的团队以及长期从事软件研发、堆垛机研发、输送设备研发、存储设备研发的专家组成的研发技术队伍。"
export const i2 = {
  name: "我们是一支专业的团队。",
  desc: "我们的成员拥有多年的智慧物流技术领域专业背景，具备丰富的行业应用经验。"
}
export const i3 = {name: "我们是一支经验丰富的团队。", desc: "我们的成员具有不同领域的项目经验，且充满了朝气和创新精神。"}
export const i4 = {
  name: "我们是一支专注的团队。",
  desc: "我们的成员坚信，优质的产品和服务源自客户的信任。只有专注，才能做好系统集成。"
}
export const i5 = {
  name: "我们是一支有梦想的团队。",
  desc: "我们的成员来自五湖四海，因为一个共同的梦想：做一家真正优秀物流服务的企业，为客户提供最可靠的系统工程。"
}

/**
 * 技术资源
 */
export const technologyResources = {
  imgUrlList: [
    "https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/technology-resources1.png",
    "https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/technology-resources2.png",
    "https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/technology-resources3.png",
  ],
  content:"公司拥有强大的研发团队、先进的生产研发基地和一流的项目管理及售后服务体系，拥有多项国家发明专利、实用新型专利，以及软件产品登记证证书等，并通过ISO9001:2015质量管理体系、ISO14001:2015环境管理体系、ISO45001:2018职业健康安全管理体系的认证。",
  icon:"https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/arrow.png"
}
