<template>
  <div class="company-about-us">
    <LeftTitle title="公司简介"/>
    <div>
      <!-- 面板内容 -->
      <div class="about-us-container" id = "company-about-us-id">
        <div class="about-us-left">
          <img src="https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/20240704160343793.jpg" alt="关于我们">
        </div>
        <div class="about-us-right">
          <p class="animated fadeInUp"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;杭州建航物流科技有限公司</span>{{ companyContent1 }}</p>
          <p class="animated fadeInRight">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ companyContent2 }}</p>
          <p class="animated fadeInLeft">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ companyContent3 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {p1} from "@/api/company";
import {p2} from "@/api/company";
import {p3} from "@/api/company";
import LeftTitle from "@/components/library/base-left-title.vue";

export default {
  components: {LeftTitle},
  setup() {
    return {companyContent1: p1, companyContent2: p2, companyContent3: p3}
  },
  watch: {
    scrollTop(oldValue, newValue) {
      this.offsetHeight = document.getElementById("company-about-us-id").offsetHeight
      if (newValue > this.offsetHeight) {
        this.solution.isShow = true
      }
    }
  }
}
</script>

<style scoped lang="less">
.company-about-us {
  width: 100%;
  height: 450px;


  :first-child div {
    float: left;
    height: 410px;
    background-color: #fff;
    position: relative;
  }



  h1 {
    font-size: 40px;
    text-align: center;
    font-weight: 600;
    line-height: 1.5;
  }

  .about-us-container {
    height: 100%;

    .about-us-left {
      width: 50%;

      img {
        width: 100%;
        height: 100%;
      }

      :after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .about-us-right {
      width: 50%;

      p {
        font-size: 16px;
        padding: 30px 10px 0 30px;
        line-height: 1.5;
      }
      span{
        font-weight: 1000;
        font-size: 20px;
      }
    }
  }
}
</style>
